import { render, staticRenderFns } from "./usage.vue?vue&type=template&id=08c7d7b4&scoped=true&"
import script from "./usage.vue?vue&type=script&lang=js&"
export * from "./usage.vue?vue&type=script&lang=js&"
import style0 from "./usage.vue?vue&type=style&index=0&id=08c7d7b4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08c7d7b4",
  null
  
)

export default component.exports